define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const TRANSLATABLE_FIELDS = ['addressFieldLabel', 'emailFieldLabel', 'errorMessage', 'messageFieldLabel', 'nameFieldLabel', 'phoneFieldLabel', 'subjectFieldLabel', 'submitButtonLabel', 'successMessage', 'validationErrorMessage'];

    function fixContactFormTranslatableFields(docData, language) {
        if (!_.includes(coreUtils.languages, language)) {
            return;
        }

        const trans = coreUtils.translationsLoader.getTranslationAllKeys('contactFormTranslations', language, {}, {useOldTranslations: true});
        if (!trans) {
            return;
        }

        _.forOwn(docData, function (dataItem) {
            if (dataItem.type === 'ContactForm') {
                _.forEach(TRANSLATABLE_FIELDS, function (field) {
                    dataItem[field] = trans[field] || dataItem[field];
                });
            }
        });
    }

    function fixAddSubmitAction(docData) {
        _.forOwn(docData, function (dataItem) {
            if (dataItem.type === 'ContactForm') {
                if (!dataItem.onSubmitBehavior) {
                    dataItem.onSubmitBehavior = 'message';
                }
            }
        });
    }

    /**
     * @exports utils/dataFixer/plugins/contactFormDataFixer
     * @type {{exec: exec}}
     */
    const exports = {
        exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel) {
            const docData = pageJson.data.document_data;
            const isTemplate = _.get(rendererModel, 'siteInfo.documentType') === 'Template';
            if (!isTemplate) {
                return;
            }
            const language = coreUtils.wixUserApi.getLanguage(requestModel.cookie, currentUrl, requestModel.language);
            fixContactFormTranslatableFields(docData, language);
            fixAddSubmitAction(docData);
        }
    };

    return exports;
});
