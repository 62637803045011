define(['lodash'], function (_) {
    'use strict';
    const INPUTS_WITH_PLACEHOLDERS = [
        'wysiwyg.viewer.components.inputs.TextInput',
        'wixapps.integration.components.inputs.TextArea',
        'wysiwyg.viewer.components.inputs.TextAreaInput',
        'wysiwyg.viewer.components.inputs.ComboBoxInput',
        'wysiwyg.viewer.components.inputs.DatePicker'
    ];

    function getCompId(comp) {
        return comp.propertyQuery.replace('#', '');
    }

    function getCompProps(pageJson, comp) {
        return pageJson.data.component_properties[getCompId(comp)];
    }

    function setPlaceholderInData(pageJson, comp, placeholder) {
        const compData = pageJson.data.document_data[getCompId(comp)];
        if (compData) {
            compData.placeholder = _.get(compData, 'placeholder') || placeholder;
        }
    }

    function isInputWithPlaceholder(comp) {
        return _.includes(INPUTS_WITH_PLACEHOLDERS, comp.componentType);
    }

    function fixInputPlaceholder(pageJson, comp) {
        const placeholder = getCompProps(pageJson, comp).placeholder;
        setPlaceholderInData(pageJson, comp, placeholder);
    }

    function fixComps(pageJson, comps) {
        _.forEach(comps, function (comp) {
            if (isInputWithPlaceholder(comp)) {
                fixInputPlaceholder(pageJson, comp);
            }

            if (comp.components) {
                fixComps(pageJson, comp.components);
            }
        });
    }

    function fixPage(pageJson) {
        const structureData = pageJson.structure;
        if (!structureData) {
            return;
        }
        if (structureData.components) {
            fixComps(pageJson, structureData.components);
        }
        if (structureData.mobileComponents) {
            fixComps(pageJson, structureData.mobileComponents);
        }
        if (structureData.children) {
            fixComps(pageJson, structureData.children);
        }
    }

    /**
     * @exports utils/dataFixer/plugins/inputsPlaceholderDataFixer
     * @type {{exec: exec}}
     */
    const exports = {
        exec(pageJson) {
            fixPage(pageJson);
        }
    };

    return exports;
});
